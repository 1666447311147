import { Game } from "../../../";
import blocksFromNumberArray from "./blocksFromNumberArray";

const _ = null;

export default {
  blocks: blocksFromNumberArray([
    [_, _, 1, _, 0, _, 4, _, _, 5, _, _, 6, _, _, 4, _, 1, _, 1],
    [1, _, _, 3, _, _, _, _, _, _, 8, _, 7, _, _, _, _, _, _, _],
    [1, _, _, 3, 5, _, 6, _, 3, _, _, 7, _, 4, _, 4, _, _, _, 0],
    [1, _, _, _, _, 7, _, 5, 2, _, _, _, 7, _, _, _, _, 3, 3, _],
    [_, _, 1, _, _, _, 5, 5, _, 4, _, 6, _, 7, 5, _, _, 3, _, _],
    [_, 2, _, _, 0, _, 3, _, 6, _, 5, _, _, _, 5, 3, _, 1, _, _],
    [1, _, _, 2, _, 0, _, 5, _, 6, 4, _, _, _, 3, _, _, _, 1, 0],
    [_, _, _, 1, 2, _, _, _, 4, _, _, 2, _, 0, _, 4, _, 2, _, _],
    [1, _, 3, _, _, _, 6, _, 5, _, 3, _, 2, _, 3, _, 4, _, _, _],
    [_, 2, _, _, 7, _, 7, _, _, _, _, _, 3, _, 3, _, _, _, 3, _],
    [_, _, _, 8, 9, 9, _, 6, _, _, _, 4, 3, _, _, _, _, 3, _, 0],
    [_, _, 7, 8, _, 7, 6, _, _, 5, _, _, _, _, 1, _, _, _, _, 0],
    [_, 6, _, 9, _, _, 6, _, 7, 7, _, 2, _, _, _, 6, _, 6, _, _],
    [_, _, 7, 6, _, 6, _, _, _, 7, 6, _, _, 4, 7, _, _, 5, _, _],
    [5, _, 5, _, 5, 6, _, _, _, 7, _, 3, _, _, 8, 6, _, 5, _, 4],
    [_, _, _, _, 3, 5, _, _, 7, 6, 7, _, _, 7, _, _, 3, 4, _, _],
    [_, _, _, 5, _, _, 6, 8, _, 5, _, 3, _, _, 6, 5, 4, _, 4, _],
    [4, _, 4, 4, _, 5, 6, _, 5, _, 6, _, _, 8, _, _, _, 4, _, 2],
    [_, _, _, _, _, _, _, _, _, 3, _, _, _, _, _, _, _, _, _, 2],
    [_, 4, 4, _, 4, _, 3, _, _, _, _, _, 3, _, _, 4, 4, 3, _, _],
  ]),
  height: 20,
  name: "motociclo",
  width: 20,
} as Game;
