

































import { Component, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import Block from "./Block.vue";
import store from "../store";
import { Block as BlockType, GameState } from "../../";

@Component({
  components: { Block },
  store,
})
export default class Game extends Vue {
  @State blocks!: BlockType[][];
  @State gameState!: GameState;
  @Mutation reset!: () => void;
  @Mutation solve!: () => void;
}
