




















import { Component, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { Game as GameType } from "../../";

@Component
export default class Menu extends Vue {
  @State games!: GameType[];
  @Mutation playPredefinedGame!: (name: string) => void;

  play(name: string) {
    this.playPredefinedGame(name);
  }
}
