






























import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import store from "../store";
import { Block as BlockType, GameState } from "../../";

@Component({
  store,
})
export default class Block extends Vue {
  @Prop() block!: BlockType;
  @Prop() row!: number;
  @Prop() column!: number;
  @State gameState!: GameState;
  @Mutation setBlockNumber!: (args: {
    row: number;
    column: number;
    number: number | null;
  }) => void;
  @Mutation toggleBlockState!: (options: {
    row: number;
    column: number;
  }) => void;

  toggleState() {
    if (["running", "finished"].indexOf(this.gameState) >= 0) {
      this.toggleBlockState({ row: this.row, column: this.column });
    }
  }

  updateBlockNumber(e: Event) {
    const number = (e.target as HTMLInputElement).value;
    this.setBlockNumber({
      row: this.row,
      column: this.column,
      number: number !== "" ? Number(number) : null,
    });
  }
}
