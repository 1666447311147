import { Game } from "../../../";
import blocksFromNumberArray from "./blocksFromNumberArray";

const _ = null;

export default {
  blocks: blocksFromNumberArray([
    [3, _, 4, _, _, 3, _, _, 3, _, 0, _, _, 5, 5, _, 3, 4, _, 5, _, _, _, _, 4],
    [_, _, _, 6, _, _, _, 6, _, _, 3, _, _, _, _, _, _, _, _, _, _, _, 7, _, _],
    [3, _, 6, 6, _, 3, 5, _, _, 3, _, 3, 3, 2, _, _, 6, 4, _, _, 6, 6, _, _, 3],
    [4, _, _, _, _, _, 5, 5, _, _, 6, 5, _, 0, _, 6, _, _, _, _, _, 7, 7, _, _],
    [_, _, 6, 6, 6, _, 5, _, _, 3, 5, _, _, _, 4, 7, 9, _, 6, _, _, _, _, _, 2],
    [_, 5, _, _, 8, _, _, 3, _, _, _, _, 4, _, 6, _, _, 7, 5, _, 3, _, _, _, _],
    [_, _, 7, 8, 6, 6, _, 6, _, 6, _, _, _, 7, 7, _, _, _, 5, _, 5, 5, 6, _, 2],
    [_, _, 5, _, _, _, 4, 5, _, _, _, 2, _, _, 6, _, 1, 2, 4, 5, _, 5, _, _, _],
    [4, _, _, _, _, _, _, 5, _, _, _, _, 4, _, _, 2, 2, _, _, 4, _, _, 4, _, 0],
    [5, _, _, _, 5, _, _, 3, 5, 4, 5, _, _, 4, 4, 4, _, 3, _, _, 3, 4, 3, 2, _],
    [_, _, 3, _, 5, _, 6, 4, _, _, _, _, _, _, _, _, 5, _, 4, _, 2, 2, 2, _, _],
    [_, 3, _, 2, _, 4, _, _, _, _, 7, 6, _, 5, 7, _, _, 7, 5, 5, _, _, 0, 0, _],
    [0, _, _, 0, _, _, _, 3, _, 5, _, _, _, 5, 7, 7, 7, _, _, 6, 3, 1, _, _, _],
    [_, _, _, _, 0, _, _, _, 4, _, 7, _, _, 6, _, _, 8, 8, _, 5, _, _, _, _, _],
    [_, _, _, _, 1, _, 4, _, 4, 6, 6, 8, 7, 6, _, _, _, 7, 7, _, 2, 0, _, _, 0],
    [_, 0, 0, _, 3, _, 5, _, _, _, _, _, _, _, _, _, 6, _, 4, _, _, 0, _, _, _],
    [_, _, _, _, 4, 5, 4, _, 4, _, 4, _, _, _, 3, 4, _, _, _, 1, 0, _, 3, _, _],
    [_, _, 5, _, _, _, 4, 4, _, _, _, 7, 7, _, 2, _, _, 4, _, 1, _, 3, _, _, 4],
    [_, 5, 7, _, 7, 5, 4, _, 6, _, 5, _, _, _, _, 2, _, _, 3, _, _, _, 5, _, 4],
    [_, _, _, 6, _, 6, _, 3, _, 5, 5, _, 8, _, _, _, 3, 4, _, 4, _, _, _, _, _],
    [_, 4, _, _, 5, _, _, _, 5, _, _, _, _, _, 6, _, _, _, _, _, 5, _, _, _, _],
    [_, _, 5, 4, _, _, _, 6, _, _, 6, _, _, 8, _, 8, 7, _, _, _, 6, _, 3, _, 4],
    [_, _, 5, _, _, 6, _, _, 6, _, 5, _, _, 8, 8, _, 6, _, 6, 6, 6, 6, _, _, _],
    [0, _, _, _, 6, 5, 6, _, _, 6, _, _, _, 7, _, _, _, _, 8, _, _, _, 7, 6, 4],
    [_, _, 4, 5, 4, _, 6, 6, _, _, _, _, _, _, 5, _, _, _, 6, 6, _, _, 6, _, 3],
    [_, _, 5, _, 2, _, _, 6, _, _, 5, 6, 7, 7, _, _, _, 5, _, 6, 5, _, _, _, 4],
    [_, 4, _, 3, _, 5, 7, 7, _, _, _, 7, _, 8, _, 7, 6, _, _, 5, 6, 4, _, _, _],
    [_, _, 6, _, _, _, _, _, 7, 5, _, 6, 9, _, _, _, 5, _, 4, 6, _, 5, 4, _, _],
    [_, 3, _, _, 3, _, _, _, _, _, 7, _, 8, 5, _, 5, _, _, 5, _, _, 4, _, _, 4],
    [_, _, _, 2, _, 1, _, 1, _, 4, _, 4, _, 5, 6, _, 4, _, _, _, 2, _, _, 5, 3],
    [_, _, 1, _, _, 2, _, 3, _, _, _, 3, _, _, _, _, _, _, _, 2, _, 3, _, 3, _],
    [0, _, _, _, _, _, 3, _, 5, _, 4, _, 3, _, 6, _, _, _, _, 4, _, 4, _, _, _],
    [_, _, 3, 4, 4, 5, _, _, 5, 6, _, _, _, 6, _, 5, _, 4, 6, _, 5, _, 4, _, 2],
    [_, 2, _, 4, 4, _, _, _, _, _, _, _, 8, 8, 9, _, _, 7, _, _, _, _, _, _, _],
    [_, _, _, _, 2, _, 4, _, _, 1, _, _, _, 6, _, _, _, _, 5, _, _, 4, _, _, 1],
  ]),
  height: 35,
  name: "fiori",
  width: 25,
} as Game;
