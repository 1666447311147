








import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import Game from "./components/Game.vue";
import Menu from "./components/Menu.vue";
import Settings from "./components/Settings.vue";

@Component({
  components: { Game, Menu, Settings },
})
export default class App extends Vue {
  @State gameState!: boolean;
}
