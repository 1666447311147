import { Game } from "../../../";
import blocksFromNumberArray from "./blocksFromNumberArray";

const _ = null;

export default {
  blocks: blocksFromNumberArray([
    [_, 0, _, _, _, 0, _, _, _, _, 4, 4, 5, _, _],
    [_, _, _, _, 2, 2, _, 0, _, _, _, _, _, _, 4],
    [_, 3, 4, _, _, 4, 3, _, 2, 4, 6, _, 6, _, 4],
    [_, _, _, _, 6, _, _, _, _, 3, 4, 6, 5, _, _],
    [_, _, 5, 4, _, _, 4, 3, 0, _, 3, 5, 6, _, 3],
    [_, 3, _, _, 5, _, _, 2, _, _, 2, 4, _, _, 3],
    [_, _, _, _, _, 7, _, _, 0, 1, 2, _, _, _, 3],
    [_, _, 7, 8, _, 6, 4, _, 1, 2, 3, _, 2, _, _],
    [_, 7, 8, 8, _, _, _, _, _, _, _, 4, _, _, 0],
    [5, _, 6, _, _, 3, 3, _, 3, 5, _, 5, _, 0, _],
    [_, _, 6, _, _, _, _, _, _, _, _, _, _, _, _],
    [_, _, _, _, _, _, 3, _, 3, _, 2, _, _, _, 2],
    [_, 3, _, _, 7, _, _, _, 4, 3, 2, _, 2, _, _],
    [_, _, _, _, 9, 9, _, _, 2, _, 2, 3, _, _, _],
    [_, 3, 5, 6, _, _, 6, _, _, 1, _, 2, 3, 2, _],
  ]),
  height: 15,
  name: "volpe",
  width: 15,
} as Game;
