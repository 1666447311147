
















import { Vue, Component } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import store from "../store";

@Component({
  store,
})
export default class Settings extends Vue {
  @State height!: number;
  @State width!: number;
  @Mutation setDimensions!: (options: any) => void;
  @Mutation startGame!: () => void;

  updateHeight(e: Event) {
    const number = (e.target as HTMLInputElement).value;
    if (number !== "" && Number(number) >= 0) {
      this.setDimensions({ height: Number(number), width: this.width });
    }
  }

  updateWidth(e: Event) {
    const number = (e.target as HTMLInputElement).value;
    if (number !== "" && Number(number) >= 0) {
      this.setDimensions({ height: this.height, width: Number(number) });
    }
  }
}
