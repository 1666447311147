import { Game } from "../../../";
import blocksFromNumberArray from "./blocksFromNumberArray";

const _ = null;

export default {
  blocks: blocksFromNumberArray([
    [1, _, _, 2, _, _, 0, _, _, _, 3, _, _, _, 4, _, _, _, 0, _],
    [_, _, _, _, _, _, _, 0, 2, 3, _, _, _, 4, _, _, 2, 0, _, _],
    [_, 8, 9, 8, 5, _, _, _, 3, 3, _, 3, _, _, 6, 5, _, _, _, 0],
    [_, _, 9, _, _, _, _, _, _, 4, _, _, _, 3, 4, 3, 4, _, _, _],
    [_, 6, _, _, 3, _, _, 0, 2, 4, 5, _, _, _, _, _, _, _, 2, 0],
    [3, _, _, 6, _, _, 3, _, _, 3, _, _, _, _, 4, _, _, 4, 3, _],
    [_, 6, 7, 6, _, 4, _, 2, _, _, 4, 4, _, _, _, 7, 7, _, _, _],
    [_, _, 6, 6, _, 6, _, _, 1, 2, _, _, _, _, _, 5, _, _, _, 3],
    [2, _, _, _, 3, _, 2, _, _, _, 4, _, _, 3, _, _, _, _, _, 5],
    [_, _, 1, _, 4, _, 4, _, _, 6, _, 3, 2, _, 5, _, _, 4, _, 4],
    [_, 2, 4, _, 4, 4, 4, 5, _, 7, 6, _, _, _, _, 5, 3, 4, _, _],
    [_, _, 5, 4, _, _, 5, _, 6, _, _, 2, _, _, 4, _, 3, _, _, _],
    [_, _, 5, _, _, _, _, _, _, _, _, _, _, 3, _, _, 1, _, 0, _],
    [4, _, _, 3, 4, _, _, _, _, 1, _, _, 1, _, 3, 3, _, 0, _, _],
    [_, _, _, _, _, _, _, 2, 1, _, _, _, _, _, _, 5, 5, 3, 2, _],
    [_, _, 1, _, 2, _, 5, 3, 0, _, _, _, _, _, 4, _, 4, 3, 3, _],
    [4, _, _, _, _, 5, 4, 2, _, _, 2, _, 1, _, 6, 7, _, 6, _, 3],
    [5, 5, _, _, 4, _, 6, _, _, _, 4, 4, _, 3, _, _, 3, 3, 4, _],
    [_, 6, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, 3],
    [_, _, 4, 2, _, _, 3, _, 4, 4, _, _, 4, 3, _, _, 3, 3, _, _],
  ]),
  height: 20,
  name: "cerbiatto",
  width: 20,
} as Game;
